<template>
  <title-link-wrapper title="Wróć do listy klientów" :to="{ name: 'ClientsList' }">
    <v-container class="white pa-8 mt-4">
      <v-row class="mb-6">
        <h2 class="text font-weight-regular">Szczegóły klienta</h2>
      </v-row>
      <v-form v-model="isFormValid" ref="form">
        <v-row>
          <v-col class="pt-1" cols="12" md="6">
            <h2 class="text mb-3">Imię*</h2>
            <v-text-field
              v-model="client.firstName"
              :rules="validator.requiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
          <v-col class="pt-1" cols="12" md="6">
            <h2 class="text mb-3">Nazwisko*</h2>
            <v-text-field
              v-model="client.lastName"
              :rules="validator.requiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">Numer telefonu*</h2>
            <v-text-field
              v-model="client.phone"
              :rules="validator.phoneRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">E-mail*</h2>
            <v-text-field
              v-model="client.email"
              :rules="validator.emailRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">Kod pocztowy*</h2>
            <v-text-field
              v-model="client.zipCode"
              :rules="validator.zipCodeRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">Miejscowość*</h2>
            <v-text-field
              v-model="client.city"
              :rules="validator.requiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12">
            <h2 class="text mb-3">Ulica i nr domu*</h2>
            <v-text-field
              v-model="client.address"
              :rules="validator.requiredRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">Nazwa firmy</h2>
            <v-text-field
              v-model="client.companyName"
              :rules="validator.shortDescRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
          <v-col class="pt-0" cols="12" md="6">
            <h2 class="text mb-3">NIP</h2>
            <v-text-field
              v-model="client.NIP"
              :rules="validator.nipRules"
              outlined
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row> <v-divider class="mb-6"></v-divider></v-row>
        <v-row class="mb-6">
          <h2 class="text font-weight-regular">Szczegółowe informacje</h2>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12" md="4">
            <h2 class="text mb-3">Moc instalacji (kW)</h2>
            <v-text-field
              v-model="client.additionalInstallationInfo.power"
              :rules="validator.declaredPowerRules"
              outlined
              solo
              class="left-input"
            ></v-text-field>
          </v-col>
          <v-col class="pt-0" cols="6" md="4">
            <h2 class="text mb-3">Typ instalacji</h2>
            <v-select
              :items="installationTypes"
              outlined
              solo
              v-model="client.additionalInstallationInfo.installationType"
              clearable
              @change="onInstallationTypeChange"
            ></v-select>
          </v-col>
          <v-col class="pt-0" v-if="showIfPitchedRoof" cols="6" md="4">
            <h2 class="text mb-3">Typ dachu</h2>
            <v-select
              :items="roofTypes"
              v-model="client.additionalInstallationInfo.roofType"
              clearable
              outlined
              solo
              class="right-input"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0 mt-8" cols="4">
            <outlined-button :onClick="addClient" :loading="loading" outlined
              >Dodaj klienta</outlined-button
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </title-link-wrapper>
</template>
<script>
import OutlinedButton from '../../components/buttons/OutlinedButton.vue';
import TitleLinkWrapper from '../../components/TitleLinkWrapper.vue';
import { validators } from '../../validators/Validators';

export default {
  name: 'AddUser',
  data() {
    return {
      client: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        zipCode: '',
        city: '',
        address: '',
        companyName: '',
        NIP: '',
        additionalInstallationInfo: {
          power: '',
          roofType: '',
          installationType: '',
        },
      },
      roofTypes: [],
      installationTypes: [],
      roofTypeObjects: {},
      installationTypesObjects: {},
      showRoofType: false,
      isFormValid: false,
      loading: false,
    };
  },
  components: { TitleLinkWrapper, OutlinedButton },
  methods: {
    onInstallationTypeChange() {
      const installationType = Object.values(this.installationTypesObjects).find(
        (type) => type.nameForUser === this.client.additionalInstallationInfo.installationType
      );

      this.isRoofTypeVisible(installationType);

      this.client.additionalInstallationInfo.roofType = '';
    },
    isRoofTypeVisible(installationType) {
      if (
        installationType?.isRoofTypeDisabled ||
        !this.client.additionalInstallationInfo.installationType
      ) {
        this.showRoofType = false;
      } else {
        this.showRoofType = true;
      }
    },
    getFinalInstallationType() {
      let oneInstallationType = Object.values(this.installationTypesObjects).find(
        (status) => status.nameForUser === this.client.additionalInstallationInfo.installationType
      );
      return oneInstallationType?.value || '';
    },
    getFinalRoofType() {
      let oneRoofTypeObject = Object.values(this.roofTypeObjects).find(
        (status) => status.nameForUser === this.client.additionalInstallationInfo.roofType
      );
      return oneRoofTypeObject?.value || '';
    },
    async addClient() {
      let finalInstallationType = this.getFinalInstallationType();
      let finalRoofType = this.getFinalRoofType();

      this.$refs.form.validate();
      if (this.isFormValid) {
        const clientToSend = {
          ...this.client,
        };

        clientToSend.additionalInstallationInfo.installationType = finalInstallationType;
        clientToSend.additionalInstallationInfo.roofType = finalRoofType;

        try {
          this.loading = true;
          await this.$store.dispatch('client/addClient', {
            client: clientToSend,
          });

          this.$router.push({ name: 'ClientsList' });
          this.$toasted.global.success({
            message: 'Klient został poprawnie dodany.',
          });
        } catch (error) {
          this.$toasted.global.error({
            message: 'Nie udało się dodać klienta. Spróbuj później.',
          });
          console.error(error);
        } finally {
          this.loading = false;
        }
        this.resetData();
      } else {
        this.$toasted.global.error({
          message: 'Formularz zawiera błędy.',
        });
      }
    },
    async init() {
      this.roofTypeObjects = await this.$store.dispatch('static/getRoofTypes');
      this.installationTypesObjects = await this.$store.dispatch('static/getInstallationTypes');
      this.roofTypes = Object.values(this.roofTypeObjects).map((x) => x.nameForUser);
      this.installationTypes = Object.values(this.installationTypesObjects).map(
        (x) => x.nameForUser
      );
    },
    resetData() {
      this.client = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        zipCode: '',
        city: '',
        address: '',
        companyName: '',
        NIP: '',
        additionalInstallationInfo: {
          power: '',
          roofType: '',
          installationType: '',
        },
      };
      this.roofTypes = [];
      this.installationTypes = [];
      this.roofTypeObjects = {};
      this.installationTypesObjects = {};
      this.showRoofType = false;
      this.isFormValid = false;
      this.loading = false;
      this.$refs.form.resetValidation();
    },
    companyNameValidation() {
      return this.validator.shortDescRules;
    },
  },
  computed: {
    showIfPitchedRoof() {
      return (
        this.showRoofType &&
        this.client.additionalInstallationInfo.installationType ===
          this.installationTypesObjects?.PITCHED_ROOF.nameForUser
      );
    },
    validator() {
      return validators;
    },
  },
  async activated() {
    this.resetData();
    this.init();
  },
};
</script>
<style lang="scss" scoped></style>
